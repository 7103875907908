import React, {useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import BackgroundImage from 'gatsby-background-image'
import { FaAngleDoubleDown } from "@react-icons/all-files/fa/FaAngleDoubleDown";
import { FaAngleDoubleUp } from "@react-icons/all-files/fa/FaAngleDoubleUp";
import { Helmet } from 'react-helmet'
import PopupModal from 'components/Modals/PopupModal'
import HopApplyForm from 'components/forms/hop-apply-form'
import InfLogo from '../../content/images/inf-logo.png'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const HOPTravelPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hop_logo = data.hop_logo?.childImageSharp?.fluid
  const [infHOPStatus, setInfHOPStatus] = useState(false);
  const [popupModalVisible, setPopupModalVisible] = useState(false);

  const phoneNumber = meta.phoneNumber
  const emailAddress = meta.emailAddress
  const emailAddressUrl = "mailto:"+emailAddress
  const phoneNumberUrl = "tel:"+phoneNumber

  function showInfHOP() {
    setInfHOPStatus(true);
  }

  function hideInfHOP() {
    setInfHOPStatus(false);
  }

  return (
    <Layout location={location}>
      <Helmet>
        <title>HOP Travel Assist Plans for Travelers with Pre-Existing Plans</title>
        <meta name="description" content="A detailed explanation of the INF HOP Travel Assistance Program. Travel Assistance for International Travel."/>
        <meta name="keywords" content="Insurance for Visitors, visitor insurance usa, accident &amp; sickness insurance for Visitors to usa, accident &amp; sickness insurance for Visitors to usa, visitor accident &amp; sickness insurance usa, visitor accident &amp; sickness insurance, visitor insurance for parents, Insurance for Visitors for parents,best accident &amp; sickness insurance for Visitors to usa,visitors coverage insurance, best visitor insurance usa, travel insurance for usa visitors,Insurance for Visitors for parents from india,visitor insurance usa from india,Insurance for Visitors usa for parents from india"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="hop-travel-hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="hop travel hero background"
      >
        <div id="hop-travel-hero-section">
          <Container>
            <div className="col-lg-12">
				<br />
				<br />
			  <h1 className="text-white text-center">
                HOP TRAVEL ASSIST PLANS
              </h1>
              <p className="text-white text-center hero-text">
                Hop! Travel Assist is a travel assistance membership program for international trips, offering 100% payment for Medical and Travel Assistance
                 to the listed maximum in the membership general conditions. Hop! Travel Assistance offers guaranteed cashless claims via Hop! Assist.  
                 <strong>NOTE: This is a travel assistance product, and not an insurance product.</strong></p>
              <Link to="/hop-apply">
                <button className="hero-btn"> Get a Quote and Apply </button>
              </Link>
            </div>
            <div className="col-lg-8"></div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="hop-travel-section-1">
       <Container>
         <div className="col-lg-12">
           <div className="hop-travel-container">
            <h2 className="text-blue text-center">What is Hop! Travel Assist?</h2>
             <div className="row mt-4">
               <div className="col-lg-8">
                 <p>Hop! Travel Assist is an innovative approach to solving for travelers needs while they are visiting a new country. 
                  Hop!  provide medical assistance for travelers, ensuring peace of mind while on the road. Hop! has benefits for cruise, trip cancellation, 
                  pre-existing conditions, and much more!</p>
                 
                 <p><strong>NOTE:</strong> Hop! requires members to contact Hop Assist within certain times to access benefits. 
                 Non-Life-Threatening Medical Assistance requires notifcation within <strong>7 days</strong> of first symptom. 
                 Life-threatening Medical Assistance need to be reported within <strong>48 hours</strong> of first symptom. 
                 Travel Assistance requires notification within <strong>24 hours</strong> of the incident.</p>

                 <p>One of the unique features of Hop! Travel Assist is the guaranteed cashless claims process. This means that if a member uses Hop! assistance services, 
                  they don't have to worry about paying out of pocket for medical expenses. Instead, Hop! takes care of everything for them, making it incredibly easy 
                  and stress-free to use the program.</p>

                <p>When a member needs medical assistance while traveling, they can simply call Hop! Travel Assist to start the process. 
                  Hop! will then make all the necessary appointments for the member, including appointments with doctors, hospitals, and other healthcare providers. 
                  This eliminates the hassle and stress of trying to find medical care in an unfamiliar location.</p>

                <p>Once the member receives the necessary medical care, Hop! will take care of the payment process for them. 
                  They will work directly with the healthcare provider to ensure that the member doesn't have to pay anything out of pocket. 
                  This means that the member can focus on their recovery without having to worry about the financial aspect of their medical care.</p>
 
                 {infHOPStatus ?
                   <>
                                     
                  <p>Hop! also offers assistance for prescription drugs, dental emergencies, medical evacuation, and repatriation of remains. 
                    In case of an accidental death, Hop! provides coverage for that as well. If a traveler is required to quarantine due to COVID-19,
                     Hop! will cover hotel expenses during that time.</p>

                  <p>For travelers who need to be evacuated, Hop! offers air ambulance services as an optional add-on. 
                    Medical clearances and fit-to-fly assessments are also available. Hop! also provides referrals for physicians, hospitals, dental care,
                     and vision care, ensuring travelers have access to quality healthcare no matter where they are.</p>

                  <p>In case of trip interruptions or cancellations, Hop! provides assistance for certain events, such as the death or 
                    critical illness of an immediate family member or a serious disaster at home. If flights are delayed or cancelled, 
                    Hop! provides for the cost of rebooking. Lost or delayed baggage, lost passports or travel documents, and emergency cash transfers are all covered as well.</p>

                  <p>The travel assist program also offers security assistance while traveling outside of one's home country. 
                    Assistance is provided in cases of mass public disturbances, violent terrorist or extremist incidents, and major disasters. 
                    There is a 24/7 point of contact to report emergencies and get security and safety advice from duty managers. 
                    Optional add-ons include emergency political and security evacuations, hijacking, and kidnap for ransom.</p>
                     <a href="javascript:void(0)" className="read-less" onClick={hideInfHOP}>READ LESS <FaAngleDoubleUp className="double-arrow-up" /></a>
                   </>
                   :
                     <a href="javascript:void(0)" className="read-more" onClick={showInfHOP}>READ MORE <FaAngleDoubleDown className="double-arrow-down" /></a>
                 }
               </div>
               <div className="col-lg-4">
                  <Img fluid={hop_logo} className="hop-logo"/>
       <p className="text-blue text-center sub-title"><Link to="/policy_pdf/HOP_general_conditions.pdf" target="_blank">Hop! Travel Assist General Conditions</Link></p>
       <Link to="/hop-apply">
                <button className="hero-btn"> Get a Quote and Apply </button>
              </Link>
                  {/* <PopupModal label="Click to Get a Quote" className="inf-btn" id="hop-travel-popup"> 
                    <h2 className="mb-0"> Apply for Hop! Travel Assist!</h2>
                    <img src={InfLogo} className="mb-0"/>
               <p>Hop! Recommends enrolling for at least 61 days to get Best Price Discount</p>  
                    <h2> Hop! Travel Plan Application</h2>
                    <HopApplyForm />
                   </PopupModal>  */}
                </div>
             </div>
           </div>
         </div>

       </Container>
      </section>

     <section id="hop-travel-section-2">
      <Container>
        <div className="col-lg-12">
          <h4 className="text-center text-blue">Hop! offers several innovative features such as:</h4>
            <ul>
            <li className="text-blue"> Guaranteed Cashless Claims via Hop! Assist</li>
            <li className="text-blue"> All plans cover 100% of Eligible Medical Expenses with medical access expenses of $0, $100, $250, $500, $1,000, $2,500, and $5,000</li>
            <li className="text-blue"> Medical Assistance for Pre-Existing Conditions</li>
            <li className="text-blue"> Medical Assistance for COVID-19</li>
            <li className="text-blue"> PPO Discounts via United Healthcare (Including Discounts on Vaccinations)</li>
            <li className="text-blue"> SmartDelay included in all Memberships</li>
              </ul>
         </div>
      </Container>
     </section>

     <section id="hop-travel-section-3">
       <Container>
         <div className="col-lg-12">
           <div className="hop-travel-container">
            <h2 className="text-blue text-center">COVID-19 Medical Assistance</h2>

            <ul>
              <li>The Beneficiary must always and without exception contact the Emergency Central, <strong>Who in turn will coordinate a virtual appointment by Telemedicine.</strong></li>
              <li>According to the opinion provided by the Medical Department, if the Beneficiary presents symptoms related to COVID-19, the Assistance Center will coordinate the relevant medical consultation, according to the safety and health protocols of each country.</li>
              <li>We will cover the expenses incurred up to the medical assistance limit indicated in the voucher.</li>
              <li>The following expenses will be covered under the same limit:</li>

              <ul>
                <li><strong>Hospital Expenses for COVID-19:</strong> In case of requiring hospitalization to stabilize the Beneficiary's condition.</li>
                <li><strong>Mechanical respirator fees:</strong> If the Medical Department, together with the treating doctor, considers the use of a mechanical respirator necessary, the Central will authorize and cover said expense.</li>
              </ul>
           </ul>

           <div className="blue-bg">
            <p className="text-center text-blue important">**Important Notice**</p>
            <p className="text-center text-blue notice">Vouchers should be  purchased before trip start date.</p>
            <p className="text-center text-blue notice">Membership may be extended before expiration to avoid waiting period for new sickness assistance.</p>
            <p className="text-center text-blue notice">Extension may be subject to underwriting approval from Hop!. Please send extension requests at least week before expiration</p>
           </div>
          </div>
         </div>
       </Container>
     </section>
{/*
     <section id="hop-travel-section-4">
      <Container>
        <div className="col-lg-12">
        <h3 className="text-blue text-center">Hop! Assist 30 days Travel Assistance Cost with $0 Medical Access Expense</h3>

      <div className="row justify-content-left">
        <div className="col">
          <div className="column-tab">
            <p className="text-black column-tab-title">Name of the Plan</p>
          </div>
            <p className="tab-content text-black">Classico</p>
            <p className="tab-content text-black">Ultra</p>
            <p className="tab-content text-black">Ultra Plus</p>
            <p className="tab-content text-black">VIP</p>
            <p className="tab-content text-black">VIP Plus	</p>
        </div>

        <div className="col">
          <div className="column-tab">
            <p className="text-black column-tab-title">Trip Duration (60 days)</p>
          </div>
            <p className="tab-content text-black">Assistance Max: $10K</p>
            <p className="tab-content text-black">Assistance Max: $30K</p>
            <p className="tab-content text-black">Assistance Max: $60K</p>
            <p className="tab-content text-black">Assistance Max: $100K</p>
            <p className="tab-content text-black">Assistance Max: $250K</p>
        </div>

        <div className="col">
          <div className="column-tab">
            <p className="text-black  column-tab-title ">Plan Cost (0-65)</p>
          </div>
            <p className="tab-content text-black">$75.60</p>
            <p className="tab-content text-black">$91.50</p>
            <p className="tab-content text-black">$115.20</p>
            <p className="tab-content text-black">$151.80</p>
            <p className="tab-content text-black">$212.40</p>
        </div>

        <div className="col">
          <div className="column-tab">
            <p className="text-black  column-tab-title">Plan Cost* (66-69)</p>
          </div>
            <p className="tab-content text-black">$75.90</p>
            <p className="tab-content text-black">$111.0</p>
            <p className="tab-content text-black">$160.20</p>
            <p className="tab-content text-black">$206.40</p>
            <p className="tab-content text-black">$285.90</p>
        </div>

        <div className="col">
          <div className="column-tab">
            <p className="text-black column-tab-title">Plan Cost* (70-74)</p>
          </div>
          
          <p className="tab-content text-black">$74.70</p>
           <p className="tab-content text-black">$130.50</p>
          <p className="tab-content text-black">$205.20</p>
          <p className="tab-content text-black">$261.00</p>
          <p className="tab-content text-black">$359.10</p>
        </div>
        <div className="col">
          <div className="column-tab">
            <p className="text-black column-tab-title">Plan Cost* (75-79)</p>
          </div>
          <p className="tab-content text-black">$149.40</p>
          <p className="tab-content text-black">$261.00</p>
          <p className="tab-content text-black">$325.50</p>
          <p className="tab-content text-black">$465.00</p>
          <p className="tab-content text-black">NA</p>
        </div>
        <div className="col">
          <div className="column-tab">
            <p className="text-black column-tab-title">Plan Cost* (80-99)</p>
          </div>
          <p className="tab-content text-black">$224.10</p>
          <p className="tab-content text-black">$391.50</p>
          <p className="tab-content text-black">$615.60</p>
          <p className="tab-content text-black">$783.00</p>
          <p className="tab-content text-black">NA</p>
        </div>

      </div>
    </div>
       </Container>
     </section>
*/}

     <section id="hop-travel-section-5">
       <Container>
       <div className="col-lg-12">
         <h3 className="text-blue text-center">Membership Options With Assistance Levels</h3>

         <div className="row">
         <div className="col-lg-6">
           <div className="column-tab">
             <p className="text-black column-tab-title">Assistance Membership Options</p>
              <p className="text-black column-tab-title"></p>
           </div>
           <p className="tab-content text-black">Classico</p>
           <p className="tab-content text-black">Ultra</p>
           <p className="tab-content text-black">Ultra Plus</p>
           <p className="tab-content text-black">VIP</p>
           <p className="tab-content text-black">VIP Plus</p>
         </div>

         <div className="col-lg-6">
           <div className="column-tab">
             <p className="text-black column-tab-title2">Medical Assistance Levels</p>
             <p className="text-black column-tab-title2">Access Expense from $0 to $5,000</p>
           </div>
           <p className="tab-content text-black">$10,000</p>
           <p className="tab-content text-black">$30,000</p>
           <p className="tab-content text-black">$60,000</p>
           <p className="tab-content text-black">$100,000</p>
           <p className="tab-content text-black">$250,000</p>
         </div>
         </div>
        </div>
      </Container>
     </section>

     <section id="hop-travel-section-6">
       <Container>
        <div className="col-lg-12">
           <h3 className="text-blue text-center row-title">Pre-Existing Conditions Medical Assistance</h3>
            <p>The assistance provided for chronic and / or pre-existing diseases includes the following eventualities:</p>
              <ul>
                <li className="conditions">Acute episode or unpredictable event</li>
                <li className="conditions">Decompensation of</li>
                  <ul>
                    <li className="conditions">A Chronic issue</li>
                    <li className="conditions">A Pre-existing diseases known</li>
                    <li className="conditions">A previously asymptomatic pre-existing condition</li>
                  </ul>
              </ul>
              <p><strong>What does this mean?</strong></p>
            
              <p>For example- you have high blood pressure which suddenly spikes, and you need to visit and urgent care or emergency room. 
              This is a covered situation.</p>
              
              <p>After you go to the urgent care, you are referred to a specialist. This is a covered situation.</p>
              
              <p>The specialist does an example, prescribes medication, and then considers your situation stablized. This is a covered 
              situation and where the coverage will naturally conclude for this situation.</p>

              <p><strong>NOTE:</strong>In ALL circumstances, any medical assistance MUST be arrange by Hop! Assist.</p>
            </div>

            <div className="conditions-tab">
              <input checked="checked" id="conditions-tabone" type="radio" name="conditions" />
              <input id="conditions-tabtwo" type="radio" name="conditions" />
              <input id="conditions-tabthree" type="radio" name="conditions" />

              <nav>
                <ul>
                  <li className="conditions-tabone">
                    <label for="conditions-tabone">Medical Assistance Benefits | 100% Payment</label>
                  </li>
                 <li className="conditions-tabtwo">
                   <label for="conditions-tabtwo" className="title">Travel Assistance Benefits</label>
                  </li>
                  <li className="conditions-tabthree">
                    <label for="conditions-tabthree" className="title">Security Assistance Benefits</label>
                   </li>
                </ul>
              </nav>

              <section>
               <div className="conditions-tabone">
                <div className="row">
                  <div className="col-lg-3 p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Benefits</p>
                  </div>
                  <p className="tab-content1 text-black content-1">Medical Assistance for Accident</p>
                  <p className="tab-content1 text-black content-2">Medical Assistance for Illness</p>
                  <p className="tab-content1 text-black content-2">Medical Access Expense</p>
                  <p className="tab-content1 text-black content-3">Medical Assistance in Case of Pre-Existing Medical Condition</p>
                  <p className="tab-content1 text-black content-4">Medical Assistance for COVID-19 up to 69 years old</p>
                  <p className="tab-content1 text-black content-5">Medical Assistance for COVID-19 - 70 to 99 years old </p>
                  <p className="tab-content1 text-black content-6">Prescription Drugs</p>
                  <p className="tab-content1 text-black content-7">Dental Emergency</p>
                  <p className="tab-content1 text-black content-8">Air Ambulance (Optional)</p>
                  <p className="tab-content1 text-black content-9">Medical clearances & Fit-to-Fly Assessments</p>
                  <p className="tab-content1 text-black content-10">Physician, hospital, dental, and vision referrals via Hop Assist</p>
                  <p className="tab-content1 text-black content-11">Return Travel Arrangement</p>
                  <p className="tab-content1 text-black content-12">Emergency Prescription Replacement</p>
                  <p className="tab-content1 text-black content-13">Medical Cost containment, expense recovery, and overseas investigation</p>
                  <p className="tab-content1 text-black content-14">Dispatch of Doctor or Specialist</p>
                  <p className="tab-content1 text-black content-15">Arrangement of visitor to bedside</p>
                  <p className="tab-content1 text-black content-16">Medical Payment Arrangements</p>
                  <p className="tab-content1 text-black content-17">Medical Bill Audit</p>
                  <p className="tab-content1 text-black content-18">Shipment of Medical Records</p>
                  <p className="tab-content1 text-black content-19">Medical Equipment Rental & Replacement</p>
                  <p className="tab-content1 text-black content-20">In-Patient / Out-Patient Care Management</p>
                  <p className="tab-content1 text-black content-21">Eyeglasses & Corrective lens replacement</p>
                
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Classico</p>
                  </div>
                  <p className="tab-content2 text-black content-1">$10,000</p>
                  <p className="tab-content2 text-black content-2">$10,000</p>
                  <p className="tab-content2 text-black content-2">$0, $100, $250, $500, $1,000, $2,500, $5,000</p>
                  <p className="tab-content2 text-black content-3">$300</p>
                  <p className="tab-content2 text-black content-4">Included</p>
                  <p className="tab-content2 text-black content-5">$10,000</p>
                  <p className="tab-content2 text-black content-6">$100</p>
                  <p className="tab-content2 text-black content-7">$150</p>
                  <p className="tab-content1 text-black content-8">$10,000</p>
                  <p className="tab-content1 text-black content-9">INCLUDED</p>
                  <p className="tab-content1 text-black content-10">INCLUDED</p>
                  <p className="tab-content1 text-black content-11">INCLUDED</p>
                  <p className="tab-content1 text-black content-12">INCLUDED</p>
                  <p className="tab-content1 text-black content-13">INCLUDED</p>
                  <p className="tab-content1 text-black content-14">INCLUDED</p>
                  <p className="tab-content1 text-black content-15">$300</p>
                  <p className="tab-content1 text-black content-16">INCLUDED</p>
                  <p className="tab-content1 text-black content-17">INLCUDED</p>
                  <p className="tab-content1 text-black content-18">$75</p>
                  <p className="tab-content1 text-black content-19">$300</p>
                  <p className="tab-content1 text-black content-20">INCLUDED</p>
                  <p className="tab-content1 text-black content-21">INCLUDED</p>
                
                
                
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Ultra</p>
                  </div>
                  <p className="tab-content2 text-black content-1">$30,000</p>
                  <p className="tab-content2 text-black content-2">$30,000</p>
                  <p className="tab-content2 text-black content-2">$0, $100, $250, $500, $1,000, $2,500, $5,000</p>
                  <p className="tab-content2 text-black content-3">$500</p>
                  <p className="tab-content2 text-black content-4">Included</p>
                  <p className="tab-content2 text-black content-5">Included</p>
                  <p className="tab-content2 text-black content-6">$125</p>
                  <p className="tab-content2 text-black content-7">$300</p>
                  <p className="tab-content1 text-black content-8">$50,000</p>
                  <p className="tab-content1 text-black content-9">INCLUDED</p>
                  <p className="tab-content1 text-black content-10">INCLUDED</p>
                  <p className="tab-content1 text-black content-11">INCLUDED</p>
                  <p className="tab-content1 text-black content-12">INCLUDED</p>
                  <p className="tab-content1 text-black content-13">INCLUDED</p>
                  <p className="tab-content1 text-black content-14">INCLUDED</p>
                  <p className="tab-content1 text-black content-15">$500</p>
                  <p className="tab-content1 text-black content-16">INCLUDED</p>
                  <p className="tab-content1 text-black content-17">INLCUDED</p>
                  <p className="tab-content1 text-black content-18">$75</p>
                  <p className="tab-content1 text-black content-19">$400</p>
                  <p className="tab-content1 text-black content-20">INCLUDED</p>
                  <p className="tab-content1 text-black content-21">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>Ultra Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black content-1">$60,000</p>
                  <p className="tab-content2 text-black content-2">$60,000</p>
                  <p className="tab-content2 text-black content-2">$0, $100, $250, $500, $1,000, $2,500, $5,000</p>
                  <p className="tab-content2 text-black content-3">$700</p>
                  <p className="tab-content2 text-black content-4">Included</p>
                  <p className="tab-content2 text-black content-5">Included</p>
                  <p className="tab-content2 text-black content-6">$150</p>
                  <p className="tab-content2 text-black content-7">$750</p>
                  <p className="tab-content1 text-black content-8">$50,000</p>
                  <p className="tab-content1 text-black content-9">INCLUDED</p>
                  <p className="tab-content1 text-black content-10">INCLUDED</p>
                  <p className="tab-content1 text-black content-11">INCLUDED</p>
                  <p className="tab-content1 text-black content-12">INCLUDED</p>
                  <p className="tab-content1 text-black content-13">INCLUDED</p>
                  <p className="tab-content1 text-black content-14">INCLUDED</p>
                  <p className="tab-content1 text-black content-15">$700</p>
                  <p className="tab-content1 text-black content-16">INCLUDED</p>
                  <p className="tab-content1 text-black content-17">INLCUDED</p>
                  <p className="tab-content1 text-black content-18">$75</p>
                  <p className="tab-content1 text-black content-19">$500</p>
                  <p className="tab-content1 text-black content-20">INCLUDED</p>
                  <p className="tab-content1 text-black content-21">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">VIP</p>
                  </div>
                  <p className="tab-content2 text-black content-1">$100,000</p>
                  <p className="tab-content2 text-black content-2">$100,000</p>
                  <p className="tab-content2 text-black content-2">$0, $100, $250, $500, $1,000, $2,500, $5,000</p>
                  <p className="tab-content2 text-black content-3">$2,000</p>
                  <p className="tab-content2 text-black content-4">Included</p>
                  <p className="tab-content2 text-black content-5">Included</p>
                  <p className="tab-content2 text-black content-6">$175</p>
                  <p className="tab-content2 text-black content-7">$1,000</p>
                  <p className="tab-content1 text-black content-8">$50,000</p>
                  <p className="tab-content1 text-black content-9">INCLUDED</p>
                  <p className="tab-content1 text-black content-10">INCLUDED</p>
                  <p className="tab-content1 text-black content-11">INCLUDED</p>
                  <p className="tab-content1 text-black content-12">INCLUDED</p>
                  <p className="tab-content1 text-black content-13">INCLUDED</p>
                  <p className="tab-content1 text-black content-14">INCLUDED</p>
                  <p className="tab-content1 text-black content-15">$900</p>
                  <p className="tab-content1 text-black content-16">INCLUDED</p>
                  <p className="tab-content1 text-black content-17">INLCUDED</p>
                  <p className="tab-content1 text-black content-18">$75</p>
                  <p className="tab-content1 text-black content-19">$600</p>
                  <p className="tab-content1 text-black content-20">INCLUDED</p>
                  <p className="tab-content1 text-black content-21">INCLUDED</p>


                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>VIP Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black content-1">$250,000</p>
                  <p className="tab-content2 text-black content-2">$250,000</p>
                  <p className="tab-content2 text-black content-2">$0, $100, $250, $500, $1,000, $2,500, $5,000</p>
                  <p className="tab-content2 text-black content-3">$6,000</p>
                  <p className="tab-content2 text-black content-4">Included</p>
                  <p className="tab-content2 text-black content-5">Included</p>
                  <p className="tab-content2 text-black content-6">$200</p>
                  <p className="tab-content2 text-black content-7">$1,250</p>
                  <p className="tab-content1 text-black content-8">$50,000</p>
                  <p className="tab-content1 text-black content-9">INCLUDED</p>
                  <p className="tab-content1 text-black content-10">INCLUDED</p>
                  <p className="tab-content1 text-black content-11">INCLUDED</p>
                  <p className="tab-content1 text-black content-12">INCLUDED</p>
                  <p className="tab-content1 text-black content-13">INCLUDED</p>
                  <p className="tab-content1 text-black content-14">INCLUDED</p>
                  <p className="tab-content1 text-black content-15">$1,500</p>
                  <p className="tab-content1 text-black content-16">INCLUDED</p>
                  <p className="tab-content1 text-black content-17">INLCUDED</p>
                  <p className="tab-content1 text-black content-18">$75</p>
                  <p className="tab-content1 text-black content-19">$1,000</p>
                  <p className="tab-content1 text-black content-20">INCLUDED</p>
                  <p className="tab-content1 text-black content-21">INCLUDED</p>
                </div>
                </div>
              </div>

                <div className="conditions-tabtwo">
                <div className="row">
                  <div className="col-lg-3 p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Benefits</p>
                  </div>
                  <p className="tab-content1 text-black con-1">Repatriation of Mortal Remains</p>
                  <p className="tab-content1 text-black con-2">Accidental Death (up to 99 years old)*</p>
                  <p className="tab-content1 text-black con-3">Hotel Expenses due to COVID Quarantine</p>
                  <p className="tab-content1 text-black con-4">Trip Interruption (Death or Critical Illness of Immediate Family Member)</p>
                  <p className="tab-content1 text-black con-5">Trip Interruption (Serious Disaster at Home)</p>
                  <p className="tab-content1 text-black con-6">Trip Cancellation (up to 74 years old)</p>
                  <p className="tab-content1 text-black con-7">Delayed or Cancelled Flight</p>
                  <p className="tab-content1 text-black con-8">Missed Flight Connection / Coordination</p>
                  <p className="tab-content1 text-black con-9">Expenses for Delay Luggage</p>
                  <p className="tab-content1 text-black con-10">Total and Definitive Loss of Baggage</p>
                  <p className="tab-content1 text-black con-11">Flight Rebooking</p>
                  <p className="tab-content1 text-black con-12">Hotel Rebooking</p>
                  <p className="tab-content1 text-black con-13">Rental Vehicle Rebooking</p>
                  <p className="tab-content1 text-black con-14">Lost Passport & Travel Docs</p>
                  <p className="tab-content1 text-black con-15">Emergency Cash Transfer</p>
                  <p className="tab-content1 text-black con-16">Guaranteed Hotel Check-in</p>
                  <p className="tab-content1 text-black con-17">Roadside Assistance</p>
                  <p className="tab-content1 text-black con-18">Up-to-the-minute Travel Delay Reports</p>
                  </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Classico</p>
                  </div>
                  <p className="tab-content2 text-black con-1">$10,000</p>
                  <p className="tab-content2 text-black con-2">$10,000</p>
                  <p className="tab-content2 text-black con-3">$300</p>
                  <p className="tab-content2 text-black con-4">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-5">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-6">NA</p>
                  <p className="tab-content2 text-black con-7">NA</p>
                  <p className="tab-content2 text-black con-8">$100</p>
                  <p className="tab-content2 text-black con-9">$200</p>
                  <p className="tab-content2 text-black con-10">$300</p>
                  <p className="tab-content2 text-black con-11">INCLUDED</p>
                  <p className="tab-content2 text-black con-12">INCLUDED</p>
                  <p className="tab-content2 text-black con-13">INCLUDED</p>
                  <p className="tab-content2 text-black con-14">$40</p>
                  <p className="tab-content2 text-black con-15">$5,000</p>
                  <p className="tab-content2 text-black con-16">INCLUDED</p>
                  <p className="tab-content2 text-black con-17">INCLUDED</p>
                  <p className="tab-content2 text-black con-18">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Ultra</p>
                  </div>
                  <p className="tab-content2 text-black con-1">$25,000</p>
                  <p className="tab-content2 text-black con-2">$25,000</p>
                  <p className="tab-content2 text-black con-3">$500</p>
                  <p className="tab-content2 text-black con-4">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-5">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-6">$2,000</p>
                  <p className="tab-content2 text-black con-7">NA</p>
                  <p className="tab-content2 text-black con-8">$200</p>
                  <p className="tab-content2 text-black con-9">$400</p>
                  <p className="tab-content2 text-black con-10">$600</p>
                  <p className="tab-content2 text-black con-11">INCLUDED</p>
                  <p className="tab-content2 text-black con-12">INCLUDED</p>
                  <p className="tab-content2 text-black con-13">INCLUDED</p>
                  <p className="tab-content2 text-black con-14">$60</p>
                  <p className="tab-content2 text-black con-15">$5,000</p>
                  <p className="tab-content2 text-black con-16">INCLUDED</p>
                  <p className="tab-content2 text-black con-17">INCLUDED</p>
                  <p className="tab-content2 text-black con-18">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>Ultra Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black con-1">$25,000</p>
                  <p className="tab-content2 text-black con-2">$25,000</p>
                  <p className="tab-content2 text-black con-3">$750</p>
                  <p className="tab-content2 text-black con-4">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-5">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-6">$2,000</p>
                  <p className="tab-content2 text-black con-7">$150</p>
                  <p className="tab-content2 text-black con-8">$450</p>
                  <p className="tab-content2 text-black con-9">$600</p>
                  <p className="tab-content2 text-black con-10">$900</p>
                  <p className="tab-content2 text-black con-11">INCLUDED</p>
                  <p className="tab-content2 text-black con-12">INCLUDED</p>
                  <p className="tab-content2 text-black con-13">INCLUDED</p>
                  <p className="tab-content2 text-black con-14">$60</p>
                  <p className="tab-content2 text-black con-15">$5,000</p>
                  <p className="tab-content2 text-black con-16">INCLUDED</p>
                  <p className="tab-content2 text-black con-17">INCLUDED</p>
                  <p className="tab-content2 text-black con-18">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">VIP</p>
                  </div>
                  <p className="tab-content2 text-black con-1">$25,000</p>
                  <p className="tab-content2 text-black con-2">$25,000</p>
                  <p className="tab-content2 text-black con-3">$1,000</p>
                  <p className="tab-content2 text-black con-4">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-5">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-6">$2,000</p>
                  <p className="tab-content2 text-black con-7">$150</p>
                  <p className="tab-content2 text-black con-8">$500</p>
                  <p className="tab-content2 text-black con-9">$800</p>
                  <p className="tab-content2 text-black con-10">$1,200</p>
                  <p className="tab-content2 text-black con-11">INCLUDED</p>
                  <p className="tab-content2 text-black con-12">INCLUDED</p>
                  <p className="tab-content2 text-black con-13">INCLUDED</p>
                  <p className="tab-content2 text-black con-14">$90</p>
                  <p className="tab-content2 text-black con-15">$5,000</p>
                  <p className="tab-content2 text-black con-16">INCLUDED</p>
                  <p className="tab-content2 text-black con-17">INCLUDED</p>
                  <p className="tab-content2 text-black con-18">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>VIP Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black con-1">$25,000</p>
                  <p className="tab-content2 text-black con-2">$25,000</p>
                  <p className="tab-content2 text-black con-3">$1,250</p>
                  <p className="tab-content2 text-black con-4">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-5">Economy Class Ticket to Origin</p>
                  <p className="tab-content2 text-black con-6">$2,200</p>
                  <p className="tab-content2 text-black con-7">$300</p>
                  <p className="tab-content2 text-black con-8">$550</p>
                  <p className="tab-content2 text-black con-9">$1,000</p>
                  <p className="tab-content2 text-black con-10">$1,500</p>
                  <p className="tab-content2 text-black con-11">INCLUDED</p>
                  <p className="tab-content2 text-black con-12">INCLUDED</p>
                  <p className="tab-content2 text-black con-13">INCLUDED</p>
                  <p className="tab-content2 text-black con-14">$90</p>
                  <p className="tab-content2 text-black con-15">$5,000</p>
                  <p className="tab-content2 text-black con-16">INCLUDED</p>
                  <p className="tab-content2 text-black con-17">INCLUDED</p>
                  <p className="tab-content2 text-black con-18">INCLUDED</p>
                </div>
                </div>
              </div>

                <div className="conditions-tabthree">
                <div className="row">
                  <div className="col-lg-3 p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Benefits</p>
                  </div>
                  <p className="tab-content1 text-black content-1">Malicious product tamper and contamination</p>
                  <p className="tab-content1 text-black content-2">General travel risk and travel security</p>
                  <p className="tab-content1 text-black content-3">Assistance in mass public disturbances, violent terrorist, or extremist incidents</p>
                  <p className="tab-content1 text-black content-4">24/7 point of contact to report emergencies and get security and safety advice from duty managers</p>
                  <p className="tab-content1 text-black content-5">Assistance in cases of major disasters</p>
                  <p className="tab-content1 text-black content-6">Emergency political and security evacuations (Optional)</p>
                  <p className="tab-content1 text-black content-7">Hijacking (any form of conveyance) (Optional)</p>
                  <p className="tab-content1 text-black content-8">Illegal detention and arrest</p>
                  <p className="tab-content1 text-black content-9">Kidnap for ransom (Optional)</p>
                  <p className="tab-content1 text-black content-10">Monitoring and telephone advice throughout the crisis</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Classico</p>
                  </div>
                  <p className="tab-content2 text-black content-1">INCLUDED</p>
                  <p className="tab-content2 text-black content-2">INCLUDED</p>
                  <p className="tab-content2 text-black content-3">INCLUDED</p>
                  <p className="tab-content2 text-black content-4">INCLUDED</p>
                  <p className="tab-content2 text-black content-5">INCLUDED</p>
                  <p className="tab-content2 text-black content-6">$10,000</p>
                  <p className="tab-content2 text-black content-7">$10,000</p>
                  <p className="tab-content2 text-black content-8">INCLUDED</p>
                  <p className="tab-content2 text-black content-9">$10,000</p>
                  <p className="tab-content2 text-black content-10">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">Ultra</p>
                  </div>
                  <p className="tab-content2 text-black content-1">INCLUDED</p>
                  <p className="tab-content2 text-black content-2">INCLUDED</p>
                  <p className="tab-content2 text-black content-3">INCLUDED</p>
                  <p className="tab-content2 text-black content-4">INCLUDED</p>
                  <p className="tab-content2 text-black content-5">INCLUDED</p>
                  <p className="tab-content2 text-black content-6">$15,000</p>
                  <p className="tab-content2 text-black content-7">$15,000</p>
                  <p className="tab-content2 text-black content-8">INCLUDED</p>
                  <p className="tab-content2 text-black content-9">$15,000</p>
                  <p className="tab-content2 text-black content-10">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>Ultra Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black content-1">INCLUDED</p>
                  <p className="tab-content2 text-black content-2">INCLUDED</p>
                  <p className="tab-content2 text-black content-3">INCLUDED</p>
                  <p className="tab-content2 text-black content-4">INCLUDED</p>
                  <p className="tab-content2 text-black content-5">INCLUDED</p>
                  <p className="tab-content2 text-black content-6">$20,000</p>
                  <p className="tab-content2 text-black content-7">$20,000</p>
                  <p className="tab-content2 text-black content-8">INCLUDED</p>
                  <p className="tab-content2 text-black content-9">$20,000</p>
                  <p className="tab-content2 text-black content-10">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title">VIP</p>
                  </div>       
                  <p className="tab-content2 text-black content-1">INCLUDED</p>
                  <p className="tab-content2 text-black content-2">INCLUDED</p>
                  <p className="tab-content2 text-black content-3">INCLUDED</p>
                  <p className="tab-content2 text-black content-4">INCLUDED</p>
                  <p className="tab-content2 text-black content-5">INCLUDED</p>
                  <p className="tab-content2 text-black content-6">$30,000</p>
                  <p className="tab-content2 text-black content-7">$30,000</p>
                  <p className="tab-content2 text-black content-8">INCLUDED</p>
                  <p className="tab-content2 text-black content-9">$30,000</p>
                  <p className="tab-content2 text-black content-10">INCLUDED</p>
                </div>

                <div className="col p-0">
                  <div className="column-tab">
                    <p className="text-white column-tab-title"><nobr>VIP Plus</nobr></p>
                  </div>
                  <p className="tab-content2 text-black content-1">INCLUDED</p>
                  <p className="tab-content2 text-black content-2">INCLUDED</p>
                  <p className="tab-content2 text-black content-3">INCLUDED</p>
                  <p className="tab-content2 text-black content-4">INCLUDED</p>
                  <p className="tab-content2 text-black content-5">INCLUDED</p>
                  <p className="tab-content2 text-black content-6">$30,000</p>
                  <p className="tab-content2 text-black content-7">$30,000</p>
                  <p className="tab-content2 text-black content-8">INCLUDED</p>
                  <p className="tab-content2 text-black content-9">$30,000</p>
                  <p className="tab-content2 text-black content-10">INCLUDED</p>
                </div>
                </div>
                </div>
              </section>
              </div>
             </Container>
            </section>
      <section id="hop-travel-section-7">
        <Container>
          <div className="col-lg-12">
            <h3 className="text-blue text-center row-title">Benefits for Hop! Travel Assist</h3>

            <div className="row">
              <div className="col-lg-6">
                <div className="column-tab">
                  <p className="text-black column-tab-title">Medical Benefits Sample</p>
                </div>
                <ul className="tab-content1 text-black">
                  <li>Guaranteed Cashless Claims via Hop Assist</li>
                  <li>Medical assistance for accident or illness</li>
                  <li>Medical assistance for pre-existing conditions</li>
                  <li>Medical assistance for COVID-19</li>
                  <li>Prescription drugs</li>
                  <li>Dental emergency</li>
                  <li>Repatriation of Remains</li>
                  <li>Air Ambulance (Optional)</li>
                  <li>Medical clearances & Fit-to-Fly Assessments</li>
                  <li>Physician, hospital, dental, and vision referrals via Hop Assist</li>
                  <li>Emergency Prescription Replacement</li>
                  <li>Medical Cost containment, expense recovery, and overseas investigation</li>
                  <li>Dispatch of Doctor or Specialist</li>
                  <li>Medical Payment Arrangements</li>
                  <li>Medical Bill Audit</li>
                  <li>Medical Equipment Rental & Replacement</li>
                  <li>In-Patient / Out-Patient Care Management</li>
                </ul>
              </div>

                <div className="col-lg-6">
                  <div className="column-tab">
                    <p className="text-black column-tab-title">Non-Medical Benefits Sample</p>
                  </div>
                  <ul className="tab-content text-black">
                    <li>Trip Interruption (Death or Critical Illness of Immediate Family Member)</li>
                    <li>Trip Cancellation (up to age 74)</li>
                    <li>Transportation of family member due to hospitalization</li>
                    <li>Hotel Expenses due to COVID Quarantine</li>
                    <li>Missed Connection / Coordination</li>
                    <li>Baggage Delay / Lost Bag Search</li>
                    <li>Lost Baggage / Lost Bag Search</li>
                    <li>Flight Rebooking</li>
                    <li>Hotel Rebooking</li>
                    <li>Lost Passport & Travel Docs</li>
                    <li>Emergency Cash Transfer</li>
                    <li>Roadside Assistance</li>
                  </ul>
              </div>
              </div>
            </div>
        </Container>
       </section>

     
      {/*<section id="hop-travel-section-9">
        <Container>
          <div className="col-lg-12">
            <h3 className="text-blue text-center row-title">Related Articles</h3>
          </div>

          <div className="col-lg-12">
           <div className="row">
            <div className="col-lg-6">
             <h4 className="text-center">What is Full Pre-Existing<br /> Coverage?</h4>
              <Img fluid={book1_icon} className="book-icon"/>
              <button className="read-btn">Read Now</button>
             </div>
           <div className="col-lg-6">
             <h4 className="text-center">How is a Pre-Existing<br /> Condition Determined?</h4>
             <Img fluid={book2_icon} className="book-icon"/>
             <button className="read-btn">Read Now</button>
           </div>
          </div>
          </div>
        </Container>
       </section>*/}


    </Layout>
  )
}

export default HOPTravelPage

export const query = graphql`
  query HOPTravelPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        phoneNumber
        emailAddress
      }
    },
    hero_background: file(name: { eq: "hop-travel-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hop_logo: file(name: { eq: "hop-logo" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book1_icon: file(name: { eq: "book1-icon" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book2_icon: file(name: { eq: "book2-icon" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
