import React, {useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { FaAngleDoubleDown } from "@react-icons/all-files/fa/FaAngleDoubleDown";
import { FaAngleDoubleUp } from "@react-icons/all-files/fa/FaAngleDoubleUp";
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const StandardPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  //const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const infplans_big_logo = data.infplans_big_logo?.childImageSharp?.fluid
  const section2_img_right = data.section2_img_right?.childImageSharp?.fluid
  const section2_img_left = data.section2_img_left?.childImageSharp?.fluid
  
  const img_svonset = data.img_svonset?.childImageSharp?.fluid
  const img_svdeduc = data.img_svdeduc?.childImageSharp?.fluid
  const img_svcomp = data.img_svcomp?.childImageSharp?.fluid
  const img_svcoins = data.img_svcoins?.childImageSharp?.fluid
  const img_svultra = data.img_svultra?.childImageSharp?.fluid
  const img_svurgent = data.img_svurgent?.childImageSharp?.fluid
  
  const img_hero = data.img_hero?.childImageSharp?.fluid
  const img_safevista = data.img_safevista?.childImageSharp?.fluid
  const img_safevistalogo = data.img_safevistalogo?.childImageSharp?.fluid

  const book_icon = data.book_icon?.childImageSharp?.fluid
  const book2_icon = data.book2_icon?.childImageSharp?.fluid
  const cta_bg = data.cta_bg?.childImageSharp?.fluid
  //const [infBMIStatus, setInfBMIStatus] = useState(false);

  const phoneNumber = meta.phoneNumber
  const emailAddress = meta.emailAddress
  const emailAddressUrl = "mailto:"+emailAddress
  const phoneNumberUrl = "tel:"+phoneNumber


  //function showInfBMI() {
  //  setInfBMIStatus(true);
  //}

  //function hideInfBMI() {
  //  setInfBMIStatus(false);
  //}

  return (
    <Layout location={location}>
      <Helmet>
        <title>Safevista Comprehensive</title>
        <meta name="description" content="Safevista Comprehensive is your Ultimate Acute Onset Plan"/>
        <meta name="keywords" content="Safevista, acute, acute onset, inf insurance, cheap insurance for visitors, kv rao insurance, kvrao insurance,insurance for visitors, visitor insurance usa, accident &amp; sickness insurance for visitors to usa, accident &amp; sickness insurance for visitors to usa, visitor accident &amp; sickness insurance usa, visitor accident &amp; sickness insurance, visitor insurance for parents, insurance for visitors for parents"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="standard-hero-section-bg"
        fluid={img_hero}
        alt="standard"
      >
        <div id="standard-hero-section">
          <Container>
            <div className="col-lg-12">
              <h2 className="text-blue text-left">
                SafeVista
              </h2>
			  <h2 className="text-blue text-left">
                Comprehensive
              </h2>
              <h3 className="text-blue text-left">
                Your Ultimate Acute Onset Plan
              </h3>
              <p className="hero-text text-blue text-left">
                              
              </p>
              <Link to="/inf-easy-select" className="nav-link">
                <button className="hero-btn-left"> Get a Quote </button>
              </Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section1">
        <Container>
          <div className="standard-container">
            <h2 className="text-blue text-left">SafeVista Comprehensive Plan</h2>
            <div className="row mt-5">
              <div className="col-lg-8">
				<p className="text-black">SafeVista Traveler is insurance provider that offers coverage primarily to non-US residents aged 69 and under who are traveling outside of their home country. The coverage extends to the policyholder's dependents, including their lawful spouse and unmarried children, as long as they are traveling with the policyholder and dependent coverage has been elected and paid for. SafeVista Travel emphasizes providing exceptional benefits and peace of mind to ensure travelers can confidently navigate unexpected medical emergencies while away from home.</p>
				<p className="text-black">We understand the importance of having reliable coverage, especially when you're away from home. That's why SafeVista Travel goes above and beyond to deliver exceptional benefits and unmatched peace of mind. Don't let unexpected medical emergencies derail your plans—choose Safe Vista Travel today and travel with confidence.</p>
				<Img fluid={img_safevistalogo} className="big-logo text-center"/>
              </div>
              <div className="col-lg-4">
                <Img fluid={img_safevista} className="big-logo"/>
                <h3 className="text-blue text-center"></h3>
                <Link to="/inf-easy-select"><button className="row-btn"> Click to Get a Quote </button></Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
	  
      <section>
        <Container>
          <h2 className="text-center my-5">Why SafeVista Travel Stands Out?</h2>
			<div className="row">
				<div className="col-md-4">
					<div className="feature-box1">
						<div className="icon"><Img fluid={img_svcomp} /></div>
						<h4 className="text-center">Comprehensive Coverage</h4>
						<p className="text-left">With options ranging from $150,000 to $1,000,000 in total annual maximum for all accident or sickness expenses, SafeVista Traveler ensures you're covered wherever your travels take you.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="feature-box">
						<div className="icon"><Img fluid={img_svdeduc} /></div>
						<h4 className="text-center">Flexible Deductibles</h4>
						<p className="text-left">Choose from deductibles as low as $100, giving you the flexibility to tailor your plan to suit your budget and requirements.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="feature-box">
						<div className="icon"><Img fluid={img_svonset} /></div>
						<h4 className="text-center">Acute Onset Protection</h4>
						<p className="text-left">Worried about pre-existing conditions? SafeVista Traveler covers acute onset episodes of pre-existing conditions, providing you with peace of mind during your travels.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="feature-box">
						<div className="icon"><Img fluid={img_svcoins} /></div>
						<h4 className="text-center">Low Co-insurance Rate</h4>
						<p className="text-left">Benefit from a co-insurance rate of 100%/80% for in-network services, ensuring minimal out-of-pocket expenses during emergencies.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="feature-box">
						<div className="icon"><Img fluid={img_svultra} /></div>
						<h4 className="text-center">Ultra Low Cost</h4>
						<p className="text-left">Experience the best in acute onset coverage at an unprecedented value with our new ultra-low-cost program. Enjoy comprehensive protection without breaking the bank.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="feature-box">
						<div className="icon"><Img fluid={img_svurgent} /></div>
						<h4 className="text-center">Urgent Care Benefit</h4>
						<p className="text-left">Our urgent care benefit offers affordable access to medical care when you need it most. With in-network coverage, your copay is just $25 per visit, and for out-of-network services, it's only $50 per visit.</p>
					</div>
				</div>
			</div>
        </Container>
      </section>

      <section id="standard-section3">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">SafeVista Plan Coverage</h2>
            <p className="text-black text-center tab-row-title"></p>
            <div>
			<table className="table align-middle text-center fs12 blue-table">
            <thead>
                <tr>
                    <th rowspan={2}>Medical Expense Benefits</th>
                    <th colspan={2}>SafeVista Traveler Plan</th>
                </tr>
                <tr>
                    <th>In-Network</th>
                    <th>Out-of-Network</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Total Annual Maximum for all Accident or Sickness Expense Benefits</th>
                    <td colspan={2}>Options: $50,000, $100,000, $250,000, $500,000, or $1,000,000</td>
                </tr>
                <tr>
                    <th>Deductible</th>
                    <td>Deductible per Injury/Sickness<br />Options: $0, $100, $250, $500, $1,000, $2,500, or $5,000</td>
                    <td>Deductible per Injury/Sickness<br />Options: $0, $100, $250, $500, $1,000, $2,500, or $5,000</td>
                </tr>
                <tr>
                    <th>Co-insurance Rate</th>
                    <td>Insurance pays 100%/80%<br />Insured pays 0%/20%</td>
                    <td>Insurance pays 50%/50% of Usual, Reasonable & Customary (URC) Charges<br />Insured pays 50%/50%</td>
                </tr>
                <tr>
                    <th>Acute Episode of a Pre-existing Condition</th>
                    <td colspan={2}>Up to Maximum Benefit</td>
                </tr>
                <tr>
                    <th>Urgent Care Visits</th>
                    <td>$25 copay per visit</td>
                    <td>$50 copay per visit</td>
                </tr>
                <tr>
                    <th>Physiotherapy</th>
                    <td colspan={2}>$50 per visit up to a maximum of $500</td>
                </tr>
                <tr>
                    <th>Incurral Period</th>
                    <td colspan={2}>30 days after the date of Injury/Sickness</td>
                </tr>
                <tr>
                    <th>Maximum Benefit Period</th>
                    <td colspan={2}>The earlier of the date the Covered Person’s Trip ends, or 364 days from the date of a Covered Accident or Sickness</td>
                </tr>
                <tr>
                    <th>Accidental Death and Dismemberment</th>
                    <td colspan={2}>Up to $25,000 Principal Sum</td>
                </tr>
                <tr>
                    <th>Trip Interruption</th>
                    <td colspan={2}>Up to $1,000</td>
                </tr>
                <tr>
                    <th>Emergency Medical Evacuation</th>
                    <td colspan={2}>Up to $25,000</td>
                </tr>
                <tr>
                    <th>Repatriation of Mortal Remains</th>
                    <td colspan={2}>Up to $10,000</td>
                </tr>
            </tbody>
        </table>
		
            
            </div>

            
            
          </div>
        </Container>
      </section>

      
      <section id="standard-section5">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">SafeVista General Conditions</h2>
          </div>
          <div className="col-lg-12 ">
            <div className="row mt-4 justify-content-center">
              <div className="col-lg-5 text-center">
                <h3 className="text-black">Read More for Age 0-69 </h3>
                <Img fluid={book_icon} className="book-icon-img"/>
                <a href="/policy_pdf/SafeVista_Policy_Document_0_69.pdf" target="_blank">
                  <button className="read-now-btn"> Read Now </button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <BackgroundImage
        Tag="section"
        className="standard-cta-hero-section-bg"
        fluid={cta_bg}
        alt="cta"
      >
        <div id="standard-cta-section">
          <Container>
            <div className="col-lg-12">
              <h2 className="text-white text-center">Get Coverage Now</h2>
              <Link to="/apply"><button className="buy-now-btn"> Apply Now </button></Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section7">
        <Container>
                    <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Privacy Statement:</h3>
              <p className="text-white">We know that your privacy is important to you and we strive to protect the confidentiality of your non-public personal information. We do not disclose any non-public personal information about our insureds or former insureds to anyone, except as permitted or required by law. We maintain appropriate physical, electronic and procedural safeguards to ensure the security of your non-public personal information. You may obtain a detailed copy of our privacy policy by calling 408-222-1110.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Complaints:</h3>
              <p className="text-black">In the event that you remain dissatisfied and wish to make a complaint you can do so to the Complaints team at {emailAddress}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Data Protection:</h3>
              <p className="text-white">Please note that sensitive health and other information that you provide may be used by us, our representatives, the insurers and industry governing bodies and regulators to process your insurance, handle claims and prevent fraud. This may involve transferring information to other countries (some of which may have limited, or no data protection laws). We have taken steps to ensure your information is held securely. Where sensitive personal information relates to anyone other than you, you must obtain the explicit consent of the person to whom the information relates both to the disclosure of such information to us and its use as set out above. Information we hold will not be shared with third parties for marketing purposes. You have the right to access your personal records.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Note:</h3>
              <p className="text-black">This travel assistance program is not an insurance and is not subject to and does not provide any insurance benefits required by the United States’ Patient Protection and Affordable Care Act (“PPACA”). This coverage is not a general health insurance product in any manner or kind. 
       </p>
     </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default StandardPage

export const query = graphql`
  query SafevistaPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        phoneNumber
        emailAddress
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero_background: file(name: { eq: "standard-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    infplans_big_logo: file(name: { eq: "infplans-big-logo" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_right: file(name: { eq: "standard-section2-img-right" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_left: file(name: { eq: "standard-section2-img-left" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book_icon: file(name: { eq: "book-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book2_icon: file(name: { eq: "book2-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cta_bg: file(name: { eq: "cta-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_svonset: file(name: { eq: "svonset" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_svdeduc: file(name: { eq: "svdeduc" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_svcomp: file(name: { eq: "svcomp" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
	img_svcoins: file(name: { eq: "svcoins" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
	img_svultra: file(name: { eq: "svultra" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
	img_svurgent: file(name: { eq: "svurgent" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_hero: file(name: { eq: "safevistahero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
	img_safevista: file(name: { eq: "safevista1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
	img_safevistalogo: file(name: { eq: "safevista_logo" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
